/** routes **/
import {auth} from '@/router/modules/auth'
import {coachTips} from '@/router/modules/coachTips'
import {powerUps} from '@/router/modules/powerUps'
import {pricing} from '@/router/modules/pricing'
import {videoContent} from '@/router/modules/videoContent'
import NotFound from '@/views/NotFound'
import Logout from "@/views/Logout";

export const ROUTE_NAMES_ROOT = Object.freeze({
  LOGOUT: 'Logout',
  NOT_FOUND: 'Not Found'
});

/** routes without layout **/
export const root = [
  {
    path: '',
    redirect: '/login'
  },
  {
    path: '/logout',
    name: ROUTE_NAMES_ROOT.LOGOUT,
    component: Logout
  },
  {
    // not found
    path: '/not-found',
    name: ROUTE_NAMES_ROOT.NOT_FOUND,
    component: NotFound
  }
]

export default root.concat([auth, coachTips, powerUps, pricing, videoContent]);
