import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import '@/setup/font-awesome';
import apiHandler from "@/utils/handlers/ApiHandler";

/** Set up the api interceptors **/
apiHandler.setInterceptors();

createApp(App)
  .use(createPinia())
  .use(router)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount('#app')
