<template>
  <div v-if="isLoading"
       class="icon-loading-wrapper">
    <div class="spinner">
      <font-awesome-icon icon="spinner" class="fa-spin fa-spinner"/>
    </div>
  </div>
</template>

<script>
import {useRootStore} from "@/store";
import {computed} from "vue"

export default {
  name: "LoadingIcon",
  setup() {
    const rootStore = useRootStore()
    const isLoading = computed(() => rootStore.isPageLoading)

    return {
      rootStore,
      isLoading
    }
  }
}
</script>

<style scoped>
</style>
