import LocalDataHandler from "@/utils/handlers/LocalDataHandler";
import apiHandler from '@/utils/handlers/ApiHandler'
import apiErrorHandler from "@/utils/handlers/errorHandler/ApiErrorHandler";

export const LOGIN_SUCCESS = 'loginSuccess';
export const LOGIN_FAILURE = 'loginFailure';
export const SET_LOGOUT = 'setLogout';

export const actions = {
  loginSuccess(payload) {
    LocalDataHandler.setKeepMeLoggedIn(payload);
    LocalDataHandler.setAccessToken(payload.access_token, payload.expires_in);
    LocalDataHandler.setRefreshToken(payload.refresh_token, LocalDataHandler.VALUES.REFRESH_TOKEN_EXPIRE_TIME);
    this.isAuthenticated = true;
    this.authError = '';
  },
  loginFailure(error) {
    this.isAuthenticated = false;
    this.authError = apiErrorHandler.getErrorText(error.response.data);
  },
  logout() {
    LocalDataHandler.destroy();
    LocalDataHandler.clearLocalData();
    apiHandler.setAuthHandler(false);
    this.isAuthenticated = false;
    this.authError = '';
  }
}
