import {defineStore} from 'pinia'
import {actions} from './actions';
import {apiActions} from './api';
import {getters} from './getters';

const state = () => ({
  isLoading: false,
  selectedMenuId: undefined,
  /** Modal **/
  isModalShown: false,
  modalContent: {},
  modalSettings: {},
  modalCallback: () => null,
  /** video player **/
  video: undefined,
  isVideoOverlayShown: false,
  /** Api error **/
  apiError: ''
})

export const useRootStore = defineStore('root', {
  state,
  getters,
  actions: {...actions, ...apiActions}
})
