<template>
  <div>
    Logging out...
  </div>
</template>

<script>
import {useAuthStore} from "@/store/auth";
import {useRouter} from "vue-router";
import {ROUTE_NAMES_AUTH} from "@/router/modules/auth";

export default {
  name: 'Logout',
  setup() {
    const authStore = useAuthStore();
    const router = useRouter();

    const logout = () => {
      authStore.logout();
      router.push({name: ROUTE_NAMES_AUTH.LOGIN});
    }

    logout();
  }
}
</script>

<style scoped lang="scss">
</style>
