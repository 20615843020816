export const actions = {
  setLoading(isLoading) {
    this.isLoading = isLoading;
  },
  setSelectedMenu(id) {
    this.selectedMenuId = id;
  },
  /** Modal **/
  setModalOptions({modalContent, modalSettings, modalCallback}) {
    this.modalContent = modalContent;
    this.modalSettings = modalSettings;
    this.modalCallback = modalCallback;
    this.isModalShown = true;
  },
  resetModalOptions() {
    this.modalContent = {};
    this.modalSettings = {};
    this.modalCallback = {};
    this.isModalShown = false
  },
  /** video player **/
  setVideoPlayerData(video) {
    this.video = video;
    this.isVideoOverlayShown = true;
  },
  resetVideoPlayerData() {
    this.video = undefined;
    this.isVideoOverlayShown = false;
  },
  setApiError(error) {
    this.apiError = error.response.data.error;
  }
}
