import AuthGuard from '@/router/guards/auth'

const Layout = () => import("@/views/manager/Layout.vue");
const Overview = () => import("@/views/manager/coach-tips/Overview.vue");
const Create = () => import("@/views/manager/coach-tips/Create.vue");
const Edit = () => import("@/views/manager/coach-tips/Edit.vue");

/**
 * Enum for the 'home'-module route names.
 * @enum
 */
export const ROUTE_NAMES_COACH_TIPS = Object.freeze({
  OVERVIEW: 'CoachTipOverview',
  COACH_TIP_CREATE: 'CoachTipCreate',
  COACH_TIP_EDIT: 'CoachTipEdit'
});

export const coachTips = {
  path: "/coach-tips",
  meta: {
    guard: AuthGuard
  },
  component: Layout,
  children: [
    {
      path: "overview",
      name: ROUTE_NAMES_COACH_TIPS.OVERVIEW,
      component: Overview
    },
    {
      path: "create",
      name: ROUTE_NAMES_COACH_TIPS.COACH_TIP_CREATE,
      component: Create
    },
    {
      path: "edit/:id",
      name: ROUTE_NAMES_COACH_TIPS.COACH_TIP_EDIT,
      component: Edit
    }
  ]
}
