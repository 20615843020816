import AuthGuard from '@/router/guards/auth'

const Layout = () => import("@/views/manager/Layout.vue");
const Overview = () => import("@/views/manager/video-content/Overview.vue");
const VIDEO_CATEGORY_CREATE = () => import("@/views/manager/video-content/CategoryCreate");
const VIDEO_CATEGORY_EDIT = () => import("@/views/manager/video-content/CategoryEdit");
const VIDEO_CREATE = () => import("@/views/manager/video-content/Create");
const VIDEO_EDIT = () => import("@/views/manager/video-content/Edit");

/**
 * Enum for the 'home'-module route names.
 * @enum
 */
export const ROUTE_NAMES_VIDEO_CONTENT = Object.freeze({
  OVERVIEW: 'VideoOverview',
  VIDEO_CATEGORY_CREATE: 'VideoCategoryCreate',
  VIDEO_CATEGORY_EDIT: 'VideoCategoryEdit',
  VIDEO_CREATE: 'VideoCreate',
  VIDEO_EDIT: 'VideoEdit'
});

export const videoContent = {
  path: "/video-content",
  meta: {
    guard: AuthGuard
  },
  component: Layout,
  children: [
    {
      path: "overview",
      name: ROUTE_NAMES_VIDEO_CONTENT.OVERVIEW,
      component: Overview
    },
    {
      path: "category/create",
      name: ROUTE_NAMES_VIDEO_CONTENT.VIDEO_CATEGORY_CREATE,
      component: VIDEO_CATEGORY_CREATE
    },
    {
      path: "category/edit/:id",
      name: ROUTE_NAMES_VIDEO_CONTENT.VIDEO_CATEGORY_EDIT,
      component: VIDEO_CATEGORY_EDIT
    },
    {
      path: "video/create",
      name: ROUTE_NAMES_VIDEO_CONTENT.VIDEO_CREATE,
      component: VIDEO_CREATE
    },
    {
      path: "video/edit/:id",
      name: ROUTE_NAMES_VIDEO_CONTENT.VIDEO_EDIT,
      component: VIDEO_EDIT
    }
  ]
}
