import {library} from "@fortawesome/fontawesome-svg-core";

import {
  faAngleDown,
  faAngleUp,
  faArrowLeft,
  faArrowRight,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faEdit,
  faPen,
  faPlus,
  faSearch,
  faSignOutAlt,
  faSort,
  faSpinner,
  faTimes,
  faTrash,
  faXmark
} from '@fortawesome/free-solid-svg-icons'

library.add(faSpinner, faSearch, faTimes, faArrowLeft, faArrowRight, faSignOutAlt, faCaretDown, faCaretLeft,
  faCaretRight, faChevronLeft, faChevronRight, faSort, faCaretUp, faTrash, faEdit, faPlus, faPen, faChevronUp,
  faChevronDown, faCheck, faXmark, faAngleDown, faAngleUp);
