<template>
  <div class="not-found">
    The page you entered is not found.
  </div>
</template>

<script>
export default {
  name: 'NotFound',
}
</script>

<style scoped lang="scss">
.not-found {
  padding: 4rem;
}
</style>
