import LocalDataHandler from '@/utils/handlers/LocalDataHandler';
import apiHandler from '@/utils/handlers/ApiHandler';
import {useAuthStore} from '@/store/auth'

export const SEND_LOGIN = 'sendLogin';

export const apiActions = {
  sendLogin(data) {
    const store = useAuthStore()
    // Create the form data:
    const formData = new FormData();
    formData.append('grant_type', 'password');
    formData.append('client_id', process.env.VUE_APP_AUTH_CLIENT_ID);
    formData.append('username', data.username);
    formData.append('password', data.password);
    if (data.keepMeLoggedIn) {
      formData.append('persistent', data.keepMeLoggedIn);
    }
    return apiHandler.post('auth/login', formData).then(response => {
      if (apiHandler.isSuccess(response.status)) {
        store.loginSuccess(Object.assign(data, response.data));
        // apiHandler.setAuthHandler(true);
        LocalDataHandler.setAuthenticated();
        return true;
      }
      return false;
    }).catch(error => {
      store.loginFailure(error)
      return false
    });
  },
}
