const Layout = () => import('@/views/auth/Layout');
const Login = () => import('@/views/auth/Login.vue');

export const ROUTE_NAMES_AUTH = Object.freeze({
  AUTH: 'Auth',
  LOGIN: 'AuthLogin',
});

export const auth = {
  path: '/',
  name: ROUTE_NAMES_AUTH.AUTH,
  component: Layout,
  children: [
    {
      path: 'login',
      name: ROUTE_NAMES_AUTH.LOGIN,
      meta: {
        // todo: decide if you need auto login
        // guard: AutoLoginGuard
      },
      component: Login
    },
  ]
};
