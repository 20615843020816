export default {
  en: {
    default: "There is an error, please try again later.",
    invalid_grant: "Invalid username and password combination.",
    conflict_coachtip_announcement_exists: "There's already a coach tip scheduled at this time stamp."
  },
  nl: {
    default: "Er is een fout, probeer het later opnieuw.",
    invalid_grant: "Ongeldige combinatie van gebruikersnaam en wachtwoord."
  }
}
