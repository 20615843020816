import AuthGuard from '@/router/guards/auth'

const Layout = () => import("@/views/manager/Layout.vue");
const Overview = () => import("@/views/manager/pricing/Overview.vue");

/**
 * Enum for the 'home'-module route names.
 * @enum
 */
export const ROUTE_NAMES_PRICING = Object.freeze({
  OVERVIEW: 'PricingOverview'
});

export const pricing = {
  path: "/pricing",
  meta: {
    guard: AuthGuard
  },
  component: Layout,
  children: [
    {
      path: "",
      name: ROUTE_NAMES_PRICING.OVERVIEW,
      component: Overview,
      meta: {
        title: 'Home',
      }
    }
  ]
}
