<template>
  <div>
    <router-view/>
    <LoadingIcon/>
  </div>
</template>

<script>
import LoadingIcon from "@/components/generic/LoadingIcon";

export default {
  name: 'App',
  components: {
    LoadingIcon
  }
}
</script>

<style lang="scss">
@import "~@/assets/css/_layout.shared.scss";
@import "~@/assets/css/_layout.forms.scss";
</style>
