export const getters = {
  getSelectedMenuId() {
    return this.selectedMenuId;
  },
  /** Modal **/
  getIsModalShown: (state) => state.isModalShown,
  getModalContent: (state) => state.modalContent,
  getModalSettings: (state) => state.modalSettings,
  getModalCallback: (state) => state.modalCallback,
  isVideoPlayerShown() {
    return this.isVideoOverlayShown;
  },
  getVideoPlayerData() {
    return this.video;
  },
  /** Api error **/
  getApiError: (state) => state.apiError,

  /** Loading Spinner **/
  isPageLoading: (state) => state.isLoading
}
