import AuthGuard from '@/router/guards/auth'

const Layout = () => import("@/views/manager/Layout.vue");
const Overview = () => import("@/views/manager/power-ups/Overview.vue");
const Create = () => import("@/views/manager/power-ups/Create.vue");
const Edit = () => import("@/views/manager/power-ups/Edit.vue");

/**
 * Enum for the 'home'-module route names.
 * @enum
 */
export const ROUTE_NAMES_POWER_UPS = Object.freeze({
  OVERVIEW: 'PowerUpOverview',
  CREATE: 'PowerUpCreate',
  EDIT: 'PowerUpEdit'
});

export const powerUps = {
  path: "/power-ups",
  meta: {
    guard: AuthGuard
  },
  component: Layout,
  children: [
    {
      path: "overview",
      name: ROUTE_NAMES_POWER_UPS.OVERVIEW,
      component: Overview
    },
    {
      path: "create",
      name: ROUTE_NAMES_POWER_UPS.CREATE,
      component: Create
    },
    {
      path: "edit/:id",
      name: ROUTE_NAMES_POWER_UPS.EDIT,
      component: Edit
    }
  ]
}
